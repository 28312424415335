import { objKeySort } from './sign'
import { HttpRequest, HttpRequest2 } from '@/service'

export const Get2 = (url, data: object) => {
  return HttpRequest2({
    url,
    method: 'get',
    data: objKeySort(data, url),
  })
}

export const Post2 = (url, data: object) => {
  return HttpRequest2({
    url,
    method: 'post',
    data: objKeySort(data, url),
  })
}

export const Get = (url, data: object) => {
  return HttpRequest({
    url,
    method: 'get',
    data: objKeySort(data, url),
  })
}

export const Post = (url, data: object) => {
  return HttpRequest({
    url,
    method: 'post',
    data: objKeySort(data, url),
  })
}

export const Put = (url, data: object) => {
  return HttpRequest({
    url,
    method: 'put',
    data: objKeySort(data, url),
  })
}

export const Delete = (url, data: object) => {
  return HttpRequest({
    url,
    method: 'delete',
    params: objKeySort(data, url),
  })
}

// 首字母大写
export const FUC = (str) => {
  str = str.toString()
  return str.substring(0, 1).toUpperCase() + str.substring(1)
}
