import { defineStore } from 'pinia'
import * as Api from '@/utils/api'
import jwt_decode from 'jwt-decode'
import router from '@/router'

// 第一个参数是应用程序中 store 的唯一 id
export const useUsersStore = defineStore('userStore', {
  state: () => {
    return {
      CustomerInfo: null,
      CustomerSecureInfo: null,
      Token: null,
      AreaCodeList: null,
      EmployessList: null,
      AddressModified: false,
      CartCount: null,
      SelectCarts: null,
      ShowRefresh: false,
      AllFrontCategories: null,
      Suffix: null,
      PaymentStatus: false,
      Cities: null,
      HotKeys: null,
      ServiceContacts: null,
      DefaultContacts: null,
      BD4: null,
      BD13: null,
      BD56: null,
      BD216: null,
      BD180: null,
      BD166: null,
      BD216v7: null,
      BD216v1: null,
      TT10: null,
      TP: null,
      Entries: null,
      WareList: null,
      InsurancePriceRule: null,
      MaxOrderItemCount: null,
      FreightTypes: null,
      ImgSearchingList: null,
    }
  },
  getters: {
    getJwtToken: (state) => {
      return state.Token ? jwt_decode(state.Token || '') : null
    },
  },
  actions: {
    getState(apiName, apiDate, stateName, isUpdate: any) {
      return new Promise((resolve, reject) => {
        if (!this[stateName] && !isUpdate) {
          Api.Get(apiName, apiDate)
            .then((res: any) => {
              this[stateName] = res
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        } else if (this[stateName]) {
          resolve(this[stateName])
        }
      })
    },
    setToken(token) {
      this.Token = token
      if (token) {
        localStorage.setItem('token', token)
      } else {
        localStorage.removeItem('token')
      }
    },
    logout() {
      this.$reset()
      if (localStorage.getItem('token')) {
        Api.Get('auth/api/Logout', {
          token: localStorage.getItem('token'),
        }).then(
          (res) => {
            router.push('/login')
          },
          (err) => {
            router.push('/login')
          }
        )
        localStorage.removeItem('token')
      } else {
        localStorage.removeItem('token')
        router.push('/login')
      }
    },
    setState(apiName, apiDate, stateName, isUpdate: any) {
      // console.log(apiName)
      // console.log(this[stateName])
      if (!this[stateName] || isUpdate) {
        Api.Get(apiName, apiDate).then((res) => {
          this[stateName] = res
        })
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  },
})
