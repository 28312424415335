import { environment } from './environment'
import { SiteSet } from '@/utils/siteConfig'

const crypto = require('crypto')

const getmd5 = (pwd) => {
  let md5 = crypto.createHash('md5')
  md5.update(pwd)
  let password = md5.digest('hex')
  return password
}

export const Guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function objKeySort(data, url) {
  data['ApiName'] = url.substring(url.indexOf('api/'))
  const obj = { ...data }
  obj.Sign = null
  if (obj.WebSiteId) obj.WebSiteId = SiteSet().WebSiteId
  if (obj.WebsiteId) obj.WebsiteId = SiteSet().WebSiteId

  //MD5加密字段

  if (obj.pay_password) {
    obj.pay_password = crypto
      .createHash('md5')
      .update(obj.pay_password)
      .digest('hex')
  }

  if (obj.Password) {
    obj.Password = crypto.createHash('md5').update(obj.Password).digest('hex')
  }
  if (obj.PayPwd) {
    obj.PayPwd = crypto.createHash('md5').update(obj.PayPwd).digest('hex')
  }
  if (obj.NewPassWord) {
    obj.NewPassWord = crypto
      .createHash('md5')
      .update(obj.NewPassWord)
      .digest('hex')
  }
  if (obj.NewPassword) {
    obj.NewPassword = crypto
      .createHash('md5')
      .update(obj.NewPassword)
      .digest('hex')
  }
  if (obj.OldPassword) {
    obj.OldPassword = crypto
      .createHash('md5')
      .update(obj.OldPassword)
      .digest('hex')
  }
  if (obj.new_password) {
    obj.new_password = crypto
      .createHash('md5')
      .update(obj.new_password)
      .digest('hex')
  }

  // 排序的函数
  obj.UniqueId = Guid()
  obj.App_Key = environment().AppKey
  if (obj.NoLanguage) {
    delete obj.NoLanguage
  } else {
    let lang = localStorage.getItem('lang') || 'en'
    obj.Language = { 'zh-cn': 'CN', en: 'EN', km: 'KH', pe: 'PE', id: 'ID' }[lang]
  }
  let newkey = Object.keys(obj).sort((a, b) =>
    a.toLowerCase() < b.toLowerCase() ? -1 : 1
  )
  let combiningStrings = ''
  for (let i = 0; i < newkey.length; i++) {
    let isNull
    const Inquire = obj[newkey[i]]
    if (
      !Inquire ||
      Inquire.length === 0 ||
      Inquire === '<null>' ||
      typeof Inquire === 'object' ||
      Inquire.toString() === ''
    ) {
      typeof Inquire === 'string' ? (isNull = false) : (isNull = true)
    } else {
      isNull = false
    }

    if (!isNull && Inquire.length != 0) {
      combiningStrings = combiningStrings + newkey[i] + Inquire
    }
  }
  const secretBody = obj
  const secret = environment().secret
  obj.Sign = getmd5(secret + combiningStrings + secret)
  return secretBody // 返回排好序添加签名的新对象
}
